<template>
    <div class="flex-grow mx-4 my-3">
        <div class="mb-3 mx-4">
            <h2>Hold</h2>
        </div>

        <div class="rounded-sm bg-white shadow">
            <validation-observer
                v-slot="{ handleSubmit }"
                ref="observer">
                <form
                    class="p-4 flex items-start"
                    @submit.prevent="handleSubmit(create)">
                    <div class="w-1/2">
                        <v-input
                            id="newTeamName"
                            v-model="newTeamName"
                            name="Holdnavn"
                            rule="required"
                            placeholder="Angiv nyt holdnavn"/>
                    </div>
                    <button
                        type="submit"
                        class="btn --primary ml-4">
                        Opret nyt hold
                    </button>
                </form>
            </validation-observer>

            <div class="table__header">
                <div>Navn</div>
                <div></div>
            </div>

            <div
                v-for="team in teams"
                :key="team.id"
                class="table__row">
                <validation-observer
                    v-slot="{ handleSubmit }"
                    ref="observer"
                    class="w-full flex">
                    <form
                        class="w-full flex"
                        @submit.prevent="handleSubmit(update)">
                        <div
                            v-if="edittedTeam && edittedTeam.id === team.id"
                            class="flex-grow pr-8 flex">
                            <v-input
                                id="newTeamName"
                                v-model="edittedTeamName"
                                name="Holdnavn"
                                rule="required"
                                placeholder="Angiv nyt holdnavn"
                                class="w-1/2 pr-4"/>
                        </div>
                        <div
                            v-else
                            class="flex items-center pr-8 flex-grow">
                            {{ team.displayName }}
                        </div>

                        <div
                            v-if="!edittedTeam.id"
                            class="inline-flex items-center pr-4">
                            <input
                                id="isDefaultOption"
                                v-model="team.isDefaultOption"
                                type="checkbox"
                                @change="makeDefaultOption(team)">
                            <label
                                for="isDefaultOption"
                                class="pl-2 pb-0">Standard-valg</label>
                        </div>

                        <div class="flex items-center justify-end">
                            <button
                                v-if="edittedTeam && edittedTeam.id === team.id"
                                type="submit"
                                class="u-leading-none mr-4">
                                <i class="material-icons icon-button">check</i>
                            </button>
                            <i
                                v-if="edittedTeam && edittedTeam.id === team.id"
                                class="material-icons icon-button"
                                @click="cancel">cancel</i>

                            <i
                                v-if="!edittedTeam.id"
                                class="material-icons icon-button mr-4"
                                @click="edit(team)">edit</i>
                            <i
                                v-if="!edittedTeam.id"
                                class="material-icons icon-button"
                                @click="deleteTeam(team.id)">delete</i>
                        </div>
                    </form>
                </validation-observer>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import teamsService from '../core/services/teams/teams.service';
import { TeamViewObject } from '../_typedef/missile';

@Component({
    name: 'teams'
})
export default class Teams extends Vue {
    newTeamName: string = '';
    edittedTeamName: string = '';
    edittedTeam: TeamViewObject = {} as TeamViewObject;

    async created() {
        teamsService.getAll();
    }

    get teams(): TeamViewObject[] {
        return teamsService.teams;
    }

    edit(team: TeamViewObject) {
        this.edittedTeamName = team.displayName;
        this.edittedTeam = team;
    }

    cancel() {
        this.edittedTeamName = '';
        this.edittedTeam = {} as TeamViewObject;
    }

    async makeDefaultOption(team: TeamViewObject) {
        await teamsService.makeDefaultOption(team.id);
    }

    async create() {
        await teamsService.create({ name: this.newTeamName });
        this.newTeamName = '';

        (this.$refs.observer as any).reset();
    }

    async update() {
        await teamsService.update(this.edittedTeam.id, { name: this.edittedTeamName });

        this.cancel();
    }

    async deleteTeam(teamId: number) {
        if (confirm('Er du sikker?')) {
            await teamsService.delete(teamId);
        }
    }
}
</script>